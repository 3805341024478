<template>
  <b-card class="mobile-card">
    <b-container v-b-toggle="`daeappro_${item.idLigne}`">
      <b-row align-v="center">
        <b-col cols="auto" class="bg-light bg-left font-up" style="width: 80px">
          <div class="w-100">
            {{ jour }}/{{ mois }}<br />
            {{ annee }}
          </div>
        </b-col>
        <b-col>
          <b-row class="m-0" align-v="center">
            <b-col class="p-0">
              <div>{{ item.libelleProduit }}</div>
              <div class="font-down">
                <MontantComponent
                  :value="item.quantiteCommandee"
                  :options="{ minimumFractionDigits: 0 }"
                ></MontantComponent>
                {{ item.unite }}
              </div>
              <b-collapse
                :id="`daeappro_${item.idLigne}`"
                class="label font-down text-center"
                visible
              >
                <b-icon-chevron-down></b-icon-chevron-down>
                D&eacute;tails
              </b-collapse>
            </b-col>
          </b-row>
          <b-collapse :id="`daeappro_${item.idLigne}`">
            <b-row class="m-0 mt-2 font-down">
              <b-col cols="4" class="p-0">N° bon</b-col>
              <b-col cols="8" class="p-0">
                {{ item.numeroBon }}
              </b-col>
              <b-col cols="4" class="p-0">N° ligne</b-col>
              <b-col cols="8" class="p-0">
                {{ item.numeroLigne }}
              </b-col>
              <b-col cols="4" class="p-0">Statut</b-col>
              <b-col cols="8" class="p-0">
                {{ item.statut.tooltip }}
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import MontantComponent from "../MontantComponent.vue";
export default {
  name: "LigneCommandeApproCard",
  components: { MontantComponent },
  props: ["item"],
  computed: {
    jour() {
      return this.item.dateOperation.split("-")[2];
    },
    mois() {
      return this.item.dateOperation.split("-")[1];
    },
    annee() {
      return this.item.dateOperation.split("-")[0];
    },
  },
};
</script>
